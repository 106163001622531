import React, { useEffect, useState } from "react";
import { Wrapper } from "./Wrapper";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { payoutService } from "../../_services/payout.service";
import ApexCharts from "react-apexcharts";

function Earnings() {
  const [availablePayout, setAvailablePayout] = useState(0);
  const [isWalletFetching, setIsWalletFetching] = useState(false);
  const [commissions, setcommissions] = useState([]);
  const [page, setPage] = useState(1);
  const [refreshKey, setRefreshKey] = useState(new Date());
  const [walletBalance, setWalletBalance] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [isLoadingSearchResult, setLoadingSearchResult] = useState(false);
  const [dashboardData, setdashboardData] = useState(null);
  const [selectedGraph, setSelectedGraph] = useState("last7DaysEarnings");
  const [graphData, setGraphData] = useState(null);

  const [graphFromDate, setGraphFromDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [graphToDate, setgraphToDate] = useState(
    moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD")
  );

  const [chartOptions, setChartOptions] = useState({
    series: [
      {
        name: "Net Commission",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      yaxis: {
        labels: {
          formatter: function(value) {
            return `$${value.toFixed(0)}`;
          },
        },
      },
    },
  });

  const fetchGraphData = async () => {
    try {
      const result = await payoutService.getGraphData({
        chartType: selectedGraph,
        graphFromDate,
        graphToDate,
      });
      console.log(result.data.data, "hostgraphdata");

      setGraphData(result.data.data.graphData);
    } catch (error) {
      console.log("fetchGraphData", error);
    }
  };

  useEffect(() => {
    fetchGraphData();
  }, [selectedGraph, graphFromDate, graphToDate]);

  useEffect(() => {
    if (graphData) {
      const record = graphData.chartPoints;
      let dates = record.map((item) => {
        return item[0];
      });
      let earnings = record.map((item) => {
        return item[1];
      });
      console.log({ record, dates, earnings });

      setChartOptions({
        series: [{ name: "Earnings", data: earnings }],
        options: {
          ...chartOptions.options,
          xaxis: { categories: dates },
        },
      });
    }
  }, [graphData]);

  useEffect(() => {
    fetchCommissions(page, searchValue);
  }, [page, refreshKey]);

  const fetchCommissions = async (page, searchValue) => {
    setIsWalletFetching(true);
    try {
      const result = await payoutService.comissionList(page, searchValue);
      console.log(result.data , " data---- ")
      setdashboardData(result.data);
      setWalletBalance(result.data.totalFinalAmount);
      setcommissions(result.data.records);
    } catch (error) {
      console.log(error);
    }
    setIsWalletFetching(false);
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  function formatAmount(amount) {
    return parseFloat(amount).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  const handleOnSearch = (e) => {
    setPage(1);
    setSearchValue(e.target.value);
  };

  // create a debounce function to handle the search
  useEffect(() => {
    setLoadingSearchResult(true);
    const getSearchResult = setTimeout(() => {
      fetchCommissions(page, searchValue);
      setLoadingSearchResult(false);
    }, 2000);

    return () => clearTimeout(getSearchResult);
  }, [searchValue]);

  const handleRequestPayoutForAdmin = async () => {
    try {
      const result = await payoutService.handlePayoutRequestForAdmin();
      fetchCommissions(page, searchValue);
    } catch (error) {
      console.log(error);
      toast("Something Went wrong");
    }
  };

  return (
    <div>
      <ToastContainer />
      <Wrapper>
        {isWalletFetching === false ? (
          <>
            <div className="row justify-content-center gap-4 mb-5">
              <div className="d-flex dashboard-container gap-3">
                <div className="col-3 fristcol">
                  <div className="align-items-center d-flex flex-column gap-2 justify-content-center mb-3 p-4 cs-card cs-card-height w-4">
                    <p className="card-text">
                      Host total sent to payout account
                    </p>
                    <h5 className="card-title mb-0">
                      {dashboardData &&
                        dashboardData.sumOfTotalPayoutSent.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )}
                    </h5>
                    <p className="card-text">
                      Pending host payout (Processing)
                    </p>
                    <h5 className="card-title mb-0">
                      {dashboardData &&
                        dashboardData.sumOfAvailableForWithdraw.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                          }
                        )}
                    </h5>
                  </div>
                  <div className="align-items-center d-flex flex-column gap-2 justify-content-center p-4 cs-card cs-card-height w-4">
                    <p className="card-text">Total Commission</p>
                    <h5 className="card-title">
                      {walletBalance.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </h5>
                    <p className="card-text">Wallet Balance</p>
                    <h5 className="card-title">
                      {dashboardData && (
                        <>
                          {dashboardData.availableForWithdrawForAdmin <= 0
                            ? "$0"
                            : dashboardData.availableForWithdrawForAdmin.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "USD",
                                }
                              )}
                        </>
                      )}
                    </h5>

                    {dashboardData && (
                      <button
                        onClick={handleRequestPayoutForAdmin}
                        className="btn btn-secondary btn-sm py-1"
                        disabled={dashboardData.availableForRequestPayout < 0}
                      >
                        Request Payout
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-9 seccol d-flex flex-column gap-2  p-4 cs-card">
                  <div>Total commission breakdown</div>
                  <div className="d-flex gap-4">
                    <div>
                      Last 7 days
                      <h5 className="card-title">
                        {/* {walletBalance.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })} */}
                        {dashboardData &&
                          dashboardData.graph.earnings.last7Days.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD",
                            }
                          )}
                      </h5>
                    </div>
                    <div>
                      Last 30 days
                      <h5 className="card-title">
                        {dashboardData &&
                          dashboardData.graph.earnings.last30Days.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD",
                            }
                          )}
                      </h5>
                    </div>
                    <div>
                      Year to Date
                      <h5 className="card-title">
                        {dashboardData &&
                          dashboardData.graph.earnings.lastYear.toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "USD",
                            }
                          )}
                      </h5>
                    </div>
                  </div>
                  <div className="flex-1 flex-grow-1">
                    {/* <Chart
                      chartType="AreaChart"
                      width="100%"
                      height="200px"
                      data={data}
                      options={options}
                    /> */}
                    <ApexCharts
                      options={chartOptions.options}
                      series={chartOptions.series}
                      type="area"
                      height={200}
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <select
                        onChange={(e) => setSelectedGraph(e.target.value)}
                        name="daysFilter"
                        style={{
                          border: "none",
                          outline: "none",
                          background: "none",
                        }}
                      >
                        <option value="last7DaysEarnings">Last 7 Days</option>
                        <option value="last14DaysEarnings">Last 14 Days</option>
                        <option value="last30DaysEarnings">Last 30 Days</option>
                        <option value="last60DaysEarnings">Last 60 Days</option>
                        <option value="last6MonthEarnings">
                          Last 6 Months
                        </option>
                        <option value="lastYearEarnings">Last Year</option>
                        <option value="YTD">YTD</option>
                        <option value="custom">Custom</option>
                      </select>
                      {selectedGraph === "custom" && (
                        <>
                          <input
                            min={"2023-01-01"}
                            type="date"
                            className="mx-2"
                            value={graphToDate}
                            max={moment().format("YYYY-MM-DD")}
                            onChange={(e) => {
                              setgraphToDate(e.target.value);
                            }}
                            style={{
                              border: "none",
                              outline: "none",
                              background: "none",
                            }}
                          />
                          -
                          <input
                            type="date"
                            className="mx-2 "
                            value={graphFromDate}
                            max={moment().format("YYYY-MM-DD")}
                            onChange={(e) => {
                              setGraphFromDate(e.target.value);
                            }}
                            style={{
                              border: "none",
                              outline: "none",
                              background: "none",
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div>Filter</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-justify-between align-items-center mb-4">
              <h1 className="text-lg font-bold"></h1>
              <div
                className="input-group search-area"
                style={{
                  boxShadow: "0px 4px 18px 0px rgba(75, 70, 92, 0.1)",
                  // maxWidth: 350,
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search here"
                  value={searchValue}
                  onChange={handleOnSearch}
                />
                <span className="input-group-text">
                  <a href="javascript:void(0)">
                    <i className="flaticon-381-search-2"></i>
                  </a>
                </span>
              </div>
            </div>

            <div className="table-responsive">
              <table
                className="table rounded-1   display mb-4 dataTablesCard booking-table room-list-tbl table-responsive-lg "
                id="guestTable-all"
              >
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Reservation Id</th>
                    <th>Type</th>
                    <th>Guest</th>
                    <th>Host</th>
                    <th>Date</th>
                    <th>Guest Commission</th>
                    <th>Host Commission</th>
                    <th>Processing Fees</th>
                    <th>Final Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {!isLoadingSearchResult ? (
                  <>
                    {commissions.length === 0 && (
                      <div
                        className="text-center px-2 py-2"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        No Data found
                      </div>
                    )}
                    <tbody>
                      {commissions.length > 0 &&
                        commissions.map((curr, id) => (
                          <tr key={curr._id}>
                            <td>{page * id + 1}</td>

                            <td>{curr.reservationId}</td>

                            <td>
                              {curr.type === "HostPenalty"
                                ? "Host Penalty"
                                : curr.type}{" "}
                            </td>
                            <td>{curr.guestName}</td>
                            <td>{curr.hostName}</td>
                            <td>
                              {moment(curr.createdAt).format("MMMM Do, YYYY")}
                            </td>
                            <td className="text-center text-success">

                            {curr.serviceFee ? (
                                <>
                                  {curr.type === "Refund" ? (
                                    <div className="text-danger">
                                      {curr.serviceFee.toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency: "USD",
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      {curr.serviceFee.toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency: "USD",
                                        }
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <div className="text-black">N/A</div>
                              )}

                            </td>

                            <td className="text-center text-success">
                              {curr.crewmateCommission ? (
                                <>
                                  {curr.type === "Refund" ? (
                                    <div className="text-danger">
                                      {curr.crewmateCommission.toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency: "USD",
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      {curr.crewmateCommission.toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency: "USD",
                                        }
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <div className="text-black">N/A</div>
                              )}
                            </td>
                            <td className="text-center text-danger ">
                              {curr.processingFees && "-"}
                              {curr.processingFees ? (
                                curr.processingFees.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                })
                              ) : (
                                <div className="text-black">N/A</div>
                              )}
                            </td>
                            <td className="text-center text-success">
                              {curr.type === "Refund" ? (
                                <div className="text-danger">
                                  {curr.finalAmount.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </div>
                              ) : (
                                <>
                                  {curr.finalAmount.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                  })}
                                </>
                              )}
                            </td>

                            <td className="text-center text-success">
                              {curr.type === "Refund" && (
                                <div className="bg-danger-light me-1 px-2 py-1 rounded-1 text-danger">
                                  Refund to Guest
                                </div>
                              )}
                              {curr.type === "Earnings" && (
                                <div className="bg-success-light me-1 px-2 py-1 rounded-1 text-success">
                                  Added to Wallet
                                </div>
                              )}

                              {curr.type === "HostPenalty" && (
                                <div className="bg-success-light me-1 px-2 py-1 rounded-1 text-success">
                                  Added to Wallet
                                </div>
                              )}
                            </td>

                            {/* <td>
                          {curr.type === "Earnings" && (
                            <span className="bg-cyan me-1 px-2 py-1 rounded-1 text-cyan">
                              Earnings
                            </span>
                          )}

                          {curr.type === "Refund" && (
                            <span className="bg-danger-light me-1 px-2 py-1 rounded-1 text-danger">
                              Refund
                            </span>
                          )}
                        </td> */}

                            {/* <td className="">
                        {curr.paidDate
                          ? moment(curr.paidDate).format("MMMM Do, YYYY")
                          : "--"}
                      </td> */}
                          </tr>
                        ))}
                    </tbody>
                  </>
                ) : (
                  <tr>
                    <td>{"Loading..."}</td>
                  </tr>
                )}
              </table>
            </div>

            {/* <div className="pagination"> */}
            <ul class="pagination justify-content-center">
              <li class="page-item">
                <button onClick={handlePrevPage} class="page-link">
                  Previous
                </button>
              </li>
              <li class="page-item">
                <button onClick={handleNextPage} class="page-link">
                  Next
                </button>
              </li>
            </ul>
            {/* </div> */}
          </>
        ) : (
          <div>Loading...</div>
        )}
      </Wrapper>
    </div>
  );
}

export default Earnings;
