import constant from "../_config/constant";
import { authHeader } from "../_helpers";
import { history } from "../_helpers/history";
import { handleResponse } from "../_helpers/utils";

export const propertyService = {
  list,
  add,
  allPropertylist,
  update,
  updateStatus,
  // logout,
  getDetail,
  _update,
  updatePhoto,
  saveAsDraft,
  bookingDetail,
  bookinglist,
  sendPaymentReminderMail,
  updateBookingStatus,
  cancelBookingByHost,
  cancelBookingByAdmin,
  cancelBookingByDate,
  adminCancelBookingBeforeCheckIn,
  AdminCancelBookingByDate,
  bookingRefundStatus,
  isRoomDeletableService,
  handleFeaturedService,
  exportProperties,
  exportReservations,
  cancelBookingByDateAdmin
};


async function exportReservations  (query) { 
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  try {
    const response = await fetch(`${constant.apiUrl}/booking/export?${new URLSearchParams(query).toString()}`, requestOptions);
    if (!response.ok) {
      throw new Error('Failed to export Reservations');
    }
    const blob = await response.blob();
    const anchor = document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = 'reservations.csv';
    anchor.click();

    console.log('CSV file download initiated');
  } catch (error) {
    console.error('Failed to export reservations', error);
  }
 }

async function exportProperties(search,state){
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  try {
    const response = await fetch(`${constant.apiUrl}/property/export?search=${search}&selectedState=${state}`, requestOptions);
    if (!response.ok) {
      throw new Error('Failed to export properties');
    }
    const blob = await response.blob();
    const anchor = document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = 'properties.csv';
    anchor.click();

    console.log('CSV file download initiated');
  } catch (error) {
    console.error('Failed to export properties', error);
  }
}

function list(data) {
  console.log(data,"adata--")
  data.sort = data.sort ? data.sort : 0 ;
  data.isSort = data.isSort ? data.isSort : 0 ;
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  console.log("me called ============+++++++++++++++++++", requestOptions)
  return fetch(
    `${constant.apiUrl}/property/list?search=${data.search}&page=${data.page}&limit=${data.limit}&selectedState=${data.selectedState}&status=${data.selectedStatus}&isSort=${data.isSort}&&sort=${data.sort}`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

async function handleFeaturedService(id) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ id }),
  };
  const response = await fetch(
    `${constant.apiUrl}/property/handleFeatured`,
    requestOptions
  );
  const data = await handleResponse(response);
  return data;
}

function allPropertylist() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiUrl}/property/all`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function updateStatus(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(`${constant.apiUrl}/property/updateStatus`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function add(data) {
  var location = Object.values(data.location);
  var fd = new FormData();
  fd.append("aboutListing", data.aboutListing);
  fd.append("accomodationCapacity", data.accomodationCapacity);
  fd.append("address", data.address);
  fd.append("amenities", JSON.stringify(data.amenities));
  fd.append("bookingType", data.bookingType);
  fd.append("cancelationPolicy", data.cancelationPolicy);
  fd.append("cleaningFee", data.cleaningFee);
  fd.append("listingName", data.listingName);
  fd.append("parking", data.parking);
  fd.append("perMonthPrice", data.perMonthPrice);
  fd.append("perNightPrice", data.perNightPrice);
  fd.append("propertyType", data.propertyType);
  fd.append("rentalType", data.rentalType);
  fd.append("safety", JSON.stringify(data.safety));
  fd.append("securityDeposite", data.securityDeposite);
  fd.append("sleepingArrangements", JSON.stringify(data.sleepingArrangements));
  fd.append("transportation", data.transportation);
  fd.append("stayLimit", data.stayLimit ? data.stayLimit : "");
  fd.append("reservedDays", data.reservedDays ? data.reservedDays : "");
  fd.append("lineholderDays", data.lineholderDays ? data.lineholderDays : "");
  fd.append(
    "lineholderDiscountType",
    data.lineholderDiscountType ? data.lineholderDiscountType : ""
  );
  fd.append(
    "lineholderDiscount",
    data.lineholderDiscount ? data.lineholderDiscount : ""
  );
  fd.append("covidVerified", data.covidVerified);
  fd.append("location", JSON.stringify(location.reverse()));
  fd.append("hostId", data.hostId);
  fd.append("extraChargePerGuest", data.extraChargePerGuest);
  fd.append("houseRules", JSON.stringify(data.houseRules));
  fd.append(
    "apartmentNumber",
    data.apartmentNumber ? data.apartmentNumber : ""
  );
  fd.append("propertyId", data.propertyId ? data.propertyId : "");
  fd.append("photosExist", "photos" in data);

  // data.photos.forEach((file) => { fd.append('files', file.current); })

  var newFile = [];
  var newFileOrder = [];
  var oldFIle = [];

  if (data.photos && data.photos.length) {
    data.photos.forEach((file) => {
      if (file.type && file.type == "url") {
        oldFIle.push(file);
      } else {
        newFileOrder.push(file.order);
        newFile.push(file);
      }
    });
  }

  newFile.length &&
    newFile.forEach((file) => {
      fd.append("files", file.current);
    });
  console.log({ newFile, oldFIle });

  fd.append("oldFIle", JSON.stringify(oldFIle));
  fd.append("newFileOrder", JSON.stringify(newFileOrder));

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: fd,
  };

  return fetch(`${constant.apiUrl}/property/add`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function update(data) {
  var location = Object.values(data.location);
  var fd = new FormData();
  fd.append("aboutListing", data.aboutListing);
  fd.append("accomodationCapacity", data.accomodationCapacity);
  fd.append("address", data.address);
  fd.append("amenities", JSON.stringify(data.amenities));
  fd.append("bookingType", data.bookingType);
  fd.append("cancelationPolicy", data.cancelationPolicy);
  fd.append("cleaningFee", data.cleaningFee);
  fd.append("houseRules", JSON.stringify(data.houseRules));
  fd.append("listingName", data.listingName);
  fd.append("parking", data.parking);
  fd.append("perMonthPrice", data.perMonthPrice);
  fd.append("perNightPrice", data.perNightPrice);
  fd.append("propertyType", data.propertyType);
  fd.append("rentalType", data.rentalType);
  fd.append("safety", JSON.stringify(data.safety));
  fd.append("securityDeposite", data.securityDeposite);
  fd.append("sleepingArrangements", JSON.stringify(data.sleepingArrangements));
  fd.append("transportation", data.transportation);
  fd.append("stayLimit", data.stayLimit ? data.stayLimit : "");
  fd.append("reservedDays", data.reservedDays ? data.reservedDays : "");
  fd.append("lineholderDays", data.lineholderDays ? data.lineholderDays : "");
  fd.append(
    "lineholderDiscountType",
    data.lineholderDiscountType ? data.lineholderDiscountType : ""
  );
  fd.append(
    "lineholderDiscount",
    data.lineholderDiscount ? data.lineholderDiscount : ""
  );
  fd.append("covidVerified", data.covidVerified);
  fd.append("location", JSON.stringify(location.reverse()));
  fd.append("hostId", data.hostId);
  fd.append("extraChargePerGuest", data.extraChargePerGuest);
  fd.append("propertyId", data.propertyId);
  fd.append("apartmentNumber", data.apartmentNumber);

  var newFile = [];
  var newFileOrder = [];

  var oldFIle = [];

  if (data.photos && data.photos.length) {
    data.photos.forEach((file) => {
      if (file.type && file.type == "url") {
        oldFIle.push(file);
      } else {
        newFileOrder.push(file.order);
        newFile.push(file);
      }
    });
  }

  newFile.length &&
    newFile.forEach((file) => {
      fd.append("files", file.current);
    });
  console.log({ newFile, oldFIle });

  fd.append("oldFIle", JSON.stringify(oldFIle));
  fd.append("newFileOrder", JSON.stringify(newFileOrder));

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: fd,
  };
  return fetch(`${constant.apiUrl}/property/update`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function _update(data) {
  if (data.location) {
    data.location = data.location.reverse();
  }
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${constant.apiUrl}/property/_update`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}
async function isRoomDeletableService(roomId) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ roomId }),
  };

  return fetch(`${constant.apiUrl}/property/is_room_deletable`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function updatePhoto(data) {
  var fd = new FormData();
  fd.append("propertyId", data.propertyId);
  var newFile = [];
  var newFileOrder = [];
  var oldFIle = [];

  if (data.photos && data.photos.length) {
    data.photos.forEach((file) => {
      if (file.type && file.type == "url") {
        oldFIle.push(file);
      } else {
        newFileOrder.push(file.order);
        newFile.push(file);
      }
    });
  }

  newFile.length &&
    newFile.forEach((file) => {
      fd.append("files", file.current);
    });
  fd.append("oldFIle", JSON.stringify(oldFIle));
  fd.append("newFileOrder", JSON.stringify(newFileOrder));
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: fd,
  };
  return fetch(`${constant.apiUrl}/property/update/photo`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function saveAsDraft(data) {
  var location = data.location ? Object.values(data.location) : [];
  var fd = new FormData();

  fd.append("aboutListing", data.aboutListing ? data.aboutListing : "");
  fd.append(
    "accomodationCapacity",
    data.accomodationCapacity ? data.accomodationCapacity : ""
  );
  fd.append("address", data.address ? data.address : "");
  fd.append("bookingType", data.bookingType ? data.bookingType : "");
  fd.append(
    "cancelationPolicy",
    data.cancelationPolicy ? data.cancelationPolicy : ""
  );
  fd.append("cleaningFee", data.cleaningFee ? data.cleaningFee : "");
  fd.append("listingName", data.listingName ? data.listingName : "");
  fd.append("parking", data.parking ? data.parking : "");
  fd.append("perMonthPrice", data.perMonthPrice ? data.perMonthPrice : "");
  fd.append("perNightPrice", data.perNightPrice ? data.perNightPrice : "");
  fd.append("propertyType", data.propertyType ? data.propertyType : "");
  fd.append("rentalType", data.rentalType ? data.rentalType : "");
  fd.append(
    "securityDeposite",
    data.securityDeposite ? data.securityDeposite : ""
  );
  fd.append("transportation", data.transportation ? data.transportation : "");
  fd.append("stayLimit", data.stayLimit ? data.stayLimit : "");
  fd.append("reservedDays", data.reservedDays ? data.reservedDays : "");
  fd.append("lineholderDays", data.lineholderDays ? data.lineholderDays : "");
  fd.append(
    "lineholderDiscountType",
    data.lineholderDiscountType ? data.lineholderDiscountType : ""
  );
  fd.append(
    "lineholderDiscount",
    data.lineholderDiscount ? data.lineholderDiscount : ""
  );
  fd.append("covidVerified", data.covidVerified ? data.covidVerified : "");
  fd.append(
    "extraChargePerGuest",
    data.extraChargePerGuest ? data.extraChargePerGuest : ""
  );
  fd.append("propertyId", data.propertyId ? data.propertyId : "");
  fd.append(
    "apartmentNumber",
    data.apartmentNumber ? data.apartmentNumber : ""
  );
  fd.append("hostId", data.hostId ? data.hostId : "");
  fd.append("photosExist", "photos" in data);

  if (data.sleepingArrangements && data.sleepingArrangements.length) {
    fd.append(
      "sleepingArrangements",
      JSON.stringify(data.sleepingArrangements)
    );
  }
  if (location && location.length) {
    fd.append("location", JSON.stringify(location.reverse()));
  }
  if (data.safety && data.safety.length) {
    fd.append("safety", JSON.stringify(data.safety));
  }
  if (data.houseRules && data.houseRules.length) {
    fd.append("houseRules", JSON.stringify(data.houseRules));
  }
  if (data.amenities && data.amenities.length) {
    fd.append("amenities", JSON.stringify(data.amenities));
  }

  var newFile = [];
  var newFileOrder = [];
  var oldFIle = [];

  if (data.photos && data.photos.length) {
    data.photos.forEach((file) => {
      if (file.type && file.type == "url") {
        oldFIle.push(file);
      } else {
        newFileOrder.push(file.order);
        newFile.push(file);
      }
    });
  }

  newFile.length &&
    newFile.forEach((file) => {
      fd.append("files", file.current);
    });
  console.log({ newFile, oldFIle });

  fd.append("oldFIle", JSON.stringify(oldFIle));
  fd.append("newFileOrder", JSON.stringify(newFileOrder));

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: fd,
  };
  return fetch(`${constant.apiUrl}/property/saveasdraft`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function getDetail(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiUrl}/property/detail/${id}`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function bookinglist(query) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    `${constant.apiUrl}/booking?${new URLSearchParams(query).toString()}`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function updateBookingStatus({ id, status, reason }) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ bookingId: id, status , reason }),
  };
  console.log("Testing admin   ==============");
  return fetch(`${constant.apiUrl}/booking/updateStatus`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function cancelBookingByHost(bookingId) {
  console.log("Testing cancelBookingByHost ==============");
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ bookingId }),
  };
  return fetch(`${constant.apiUrl}/booking/cancelBookingByHost`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function adminCancelBookingBeforeCheckIn(bookingId) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ bookingId }),
  };
  return fetch(`${constant.apiUrl}/booking/cancelBookingByDate`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function cancelBookingByAdmin(bookingId) {
  console.log("Testing cancelBookingByHost ==============");
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ bookingId }),
  };
  return fetch(
    `${constant.apiUrl}/booking/cancelBookingByAdmin`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function cancelBookingByDate(bookingId, cancelDate, isCancelByAdmin) {
  console.log("Testing cancelBookingByHost ==============");
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ bookingId, cancelDate, isCancelByAdmin }),
  };
  return fetch(`${constant.apiUrl}/booking/cancelBookingByDate`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function cancelBookingByDateAdmin(bookingId) {
  var isCancelByAdmin = 1 ;
  console.log("Testing cancelBookingByHost ==============");
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ bookingId, isCancelByAdmin }),
  };
  return fetch(`${constant.apiHostUrl}/booking/cancelBookingByDate`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function AdminCancelBookingByDate(bookingId, cancelDate, isCancelByAdmin) {
  console.log("Testing cancelBookingByHost ==============");
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify({ bookingId, cancelDate, isCancelByAdmin }),
  };
  return fetch(`${constant.apiUrl}/booking/cancelBookingByDate`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function bookingDetail(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(`${constant.apiUrl}/booking/${id}`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function bookingRefundStatus({ bookingId }) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };
  return fetch(`${constant.apiUrl}/refund/${bookingId}`, requestOptions)
    .then(handleResponse)
    .then((refundData) => {
      return refundData;
    });
}

// function logout() {
//   // remove user from local storage to log user out
//   localStorage.removeItem(constant.TOKEN_KEY);
//   localStorage.removeItem(constant.DATA_KEY);
// }

// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);

//     if (data.statusCode == 401 || data.statusCode == 403) {
//       logout();
//       window.location.reload();
//     }
//     if (data.statusCode != 200) {
//       const error = (data && data.userMessage) || response.statusText;
//       return Promise.reject(error);
//     }
//     return data;
//   });
// }

function sendPaymentReminderMail(req) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(req),
  };
  return fetch(`${constant.apiUrl}/property/mail`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

// function cancelBookingByHost({ bookingId }) {

//   const requestOptions = {
//     method: "POST",
//     headers: { ...authHeader(), "Content-Type": "application/json" },
//     body: JSON.stringify({ bookingId }),
//   };
//   return fetch(`${constant.apiUrl}/booking/cancelBookingByHost`, requestOptions)
//     .then(handleResponse)
//     .then((user) => {
//       return user;
//     });
// }
